@import "../../../../base/styles/general/import-general-only";

$safetyGuarantee-background: $sp-secondary-hover-color !default;;
$safetyGuarantee-color: $white !default;;

.safety-guarantee-block {
	width: 100%;
	max-width: 100%;
	color: $safetyGuarantee-color;
	background-color: $safetyGuarantee-background;

	.safety-guarantee-container {
		padding: 2.5em 1.5em;

		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: center;
			padding: 1.5em;
		}
	}

	.safety-guarantee-image-wrapper {
		align-items: center;
	}

	.safety-guarantee-image-holder {
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 100px;

		background: rgba($white, 0.1);
		border-radius: 50%;
	}

	.safety-guarantee-image {
		width: 50px;
		height: 50px;
	}

	.safety-guarantee-content {
		flex-direction: column;
		padding-top: 20px;
		line-height: 20px;

		@include media-breakpoint-up(md) {
			padding-top: 0;
			padding-left: 30px;
		}

		.content-title {
			font-size: 22px;
		}
	}
}